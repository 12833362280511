@import 'import';
.root {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-bottom: 1px solid $lightwhite;

  .backButton,
  .closeButton {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 60px;
    padding: 0;

    color: $black;

    background-color: transparent;
    border: 0;

    svg,
    .Icon {
      fill: $black;
    }
  }

  .header {
    flex: 1;
    align-items: center;
    justify-content: center;
    order: 2;

    width: 100%;
    height: 60px;
    padding: 0;
  }

  &._noBackButton {
    .header {
      padding-left: 60px;
    }
  }

  .backButton {
    order: 1;
  }

  .closeButton {
    order: 3;

    :global(.Icon),
    :global(.Icon > .Svg),
    :global(.Icon > .Svg > span),
    svg {
      display: block;
      width: 30px;
      height: 30px;
    }
  }

  .subHeader {
    display: flex;
    justify-content: center;
    order: 4;

    width: 100%;
    padding: 10px 0;

    span {
      @include font($size: rem(12), $color: $grey-text, $line-height: rem(30), $weight: 500);

      height: 30px;
      padding: 0 12px;

      letter-spacing: 0;

      background-color: $lightwhite;
      border-radius: 15px;
    }
  }
}
