@import 'import';
.ArrowButtonToggleAnimated {
  display: flex;
  align-items: center;
  justify-content: center;

  width: rem(25);
  height: rem(41);
  padding: 0;

  background: transparent;
  border: 0;

  transition: transform 0.5s;

  &__up {
    transform: rotate(270deg);

    .Icon svg {
      margin: -2px 0 0 -1px;

      @include media-breakpoint-down(md) {
        margin: -4px 0 0 -2px;
      }

      @include media-breakpoint-down(sm) {
        margin: -1px 0 0 -2px;
      }
    }
  }

  &__down {
    transform: rotate(90deg);

    .Icon svg {
      margin: -2px 0 0 -2px;

      @include media-breakpoint-down(md) {
        margin: -3px 0 0 -2px;
      }

      @include media-breakpoint-down(sm) {
        margin: 0 0 0 -2px;
      }
    }
  }
}
